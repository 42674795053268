import React, { PropsWithChildren, useRef } from "react";
import { useMedia } from "react-use";
import {
  Animated,
  Animator,
  FrameNefrex,
  Illuminator,
  Text,
  cx,
  styleFrameClipOctagon,
  useFrameAssembler,
  createFrameOctagonClip,
} from "@arwes/react";
// import {createFrameOctagonClip} from "arwes";
import { css } from "@emotion/css";

import { useTheme, theme } from "../theme";

// import styles from "./Card.module.css";

type CardProps = {
  // title: string;
  // description: string;
  icon?: React.ReactNode;
};

const cardStyle = css({
  position: "relative",
  display: "flex",
  flexDirection: "column",
  clipPath: styleFrameClipOctagon({
    leftBottom: false,
    rightTop: false,
    squareSize: 40,
  }),
  // "[data-name=line]": {
  //   color: "silver",
  // },
  // ".card-back": {
  //   opacity: 0.7,
  //   zIndex: 0,
  //   transitionProperty: "all",
  //   transitionDuration: "0.2s",
  //   transitionTimingFunction: "ease-out",
  //   // "--arwes-frames-bg-color": theme.components.card.background,
  //   "[data-name=bg]": { color: theme.components.card.background },
  //   "[data-name=line]": { display: "none" },
  // },
  ".card-back-right": { position: "absolute", right: 0, top: 0 },
  ".card-back-left": { position: "absolute", left: 0, bottom: 0 },
  ".card-container": { display: "flex", flexDirection: "column", gap: 10 },

  ".card-header": { color: theme.palette.neutral(3) },
  ".card-content": { color: theme.palette.neutral(5) },
  // ".card-header": { color: theme.palette.primary.main(3) },
  // ".card-content": { color: theme.palette.primary.main(5) },

  ":hover": {
    // ".card-back": { opacity: 1 },
    ".card-header": { color: theme.palette.neutral(2) },
    ".card-content": { color: theme.palette.neutral(4) },
    // ".card-header": { color: theme.palette.primary.high(2) },
    // ".card-content": { color: theme.palette.primary.high(4) },
  },
});

export const Card = ({
  children,
  icon,
}: PropsWithChildren<CardProps>): JSX.Element => {
  const { palette, space, spacing, breakpoints } = useTheme();

  const isXL = useMedia(breakpoints.up("xl", { strip: true }), false);
  const frameRef = useRef<SVGSVGElement>(null);

  useFrameAssembler(frameRef);

  return (
    <Animated
      as="article"
      className={cx(
        cardStyle
        // "relative group flex"
        // styles.root
      )}
      style={{ ...theme.components.card }}
      animated={["fade"]}
    >
      {/* <FrameNefrex
        elementRef={frameRef}
        className="card-back"
        strokeWidth={4}
        // styled={false}
      /> */}

      {/* <Animated className="font-code leading-none text-size-12 text-primary-main-3/20 md:text-size-11">
        <div className="card-back-right">⎯ 0x1010</div>
        <div className="card-back-left">0x1010 ⎯</div>
      </Animated> */}

      {isXL && (
        <Illuminator
          style={{
            inset: 2,
            width: "calc(100% - 4px)",
            height: "calc(100% - 4px)",
            // clipPath: styleFrameClipOctagon({
            //   leftBottom: false,
            //   rightTop: false,
            //   squareSize: space(4),
            // }),
          }}
          size={spacing(40)}
          color={palette.primary.main(7, { alpha: 0.1 })}
        />
      )}

      <div
        // style={{ display: "contents" }}
        className="flex-1 flex flex-row justify-between items-center gap-4 p-6 lg:p-8"
      >
        <div
          // style={{ display: "contents" }}
          className="card-container"
        >
          {children}
        </div>

        {icon && (
          <Animator>
            <Animated
              className={cx(
                "text-[3.5rem] text-primary-main-3",
                "transition-all ease-out duration-200",
                "group-hover:scale-110 group-hover:text-primary-high-2"
              )}
              animated={["flicker"]}
            >
              {icon}
            </Animated>
          </Animator>
        )}
      </div>
    </Animated>
  );
};

const headerStyle = css({
  position: "relative",
  padding: `0 10px`,
});
Card.Header = ({ title }: { title: string }) => (
  <Animator>
    <Animated as="div" className={cx(headerStyle, "card-header")}>
      <Text
        as="h3"
        // className={cx(
        //   headerStyle,
        //   "relative",
        //   "font-header text-size-6 text-primary-main-3 group-hover:text-primary-high-2"
        // )}
        fixed
      >
        {/* <div
        className={cx(
          "absolute left-0 inset-y-1 w-1",
          "bg-current transition-all ease-out duration-200 origin-left scale-x-0",
          "group-hover:scale-x-100"
        )}
      /> */}
        {/* <h3 className="transition-all ease-out duration-200 group-hover:translate-x-4"> */}
        {title}
        {/* </h3> */}
      </Text>
    </Animated>
  </Animator>
);

const contentStyle = css({
  padding: `0 10px`,
  flex: 1,
});
Card.Content = ({ children }: PropsWithChildren<unknown>) => (
  <Animator>
    <Animated as="div" className={cx(contentStyle, "card-content")}>
      <Text
      // as="div"
      // className={cx(contentStyle, "card-content")}
      // className="font-body text-size-8 text-primary-main-5 group-hover:text-primary-high-4"
      >
        {children}
      </Text>
    </Animated>
  </Animator>
);

const actionsStyle = css({
  padding: `0 10px`,
  display: "grid",
  gridTemplateColumns: "repeat(auto-fill, minmax(min(150px, 100%), 1fr))",
  // flexDirection: "row",
  direction: "rtl",
  gap: "10px",
});
Card.Actions = ({ children }: PropsWithChildren<unknown>) => (
  <Animator>
    <Animated as="div" className={cx(actionsStyle, "card-actions")}>
      {children}
    </Animated>
  </Animator>
);

const mediaStyle = css({
  height: "150px",
  overflow: "hidden",
  // zIndex: 1,
  // clipPath: "polygon(0 1rem,1rem 0,100% 0,100% 100%,0 100%)",
  // clipPath: styleFrameClipOctagon({
  //   squareSize: "1rem",
  //   leftBottom: false,
  //   rightTop: false,
  //   rightBottom: false,
  // }),
  img: {
    display: "block",
    // "-webkit-background-size": "cover",
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
    backgroundPosition: "center",
    width: "100%",
    objectFit: "cover",
  },
});
Card.Media = ({ src, children }: PropsWithChildren<{ src: string }>) => (
  <Animator>
    <Animated as="div" className={cx(mediaStyle, "card-media")}>
      {src ? <img src={src} /> : children}
    </Animated>
  </Animator>
);
